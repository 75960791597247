import { AxiosPromise } from "axios";
import axiosClient from "@/axios";
import { PickupPointsResponse } from "@/models/PickupPointsResponse";

const pickupPointService = {
  // "eyJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6InBpY3FlckBwYXJjZWw0eW91LmNvbSIsInNlY3JldCI6IjBmZTE1ZWYyNTMxZDUzM2I1MmI1OTk2OTU1NTk1ODllIn0.XiH7f2_XIYUg577amAo_rNBHlWca-kWnOjUjy5njuIE"
  // "eyJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6InBpY3FlckBwYXJjZWw0eW91LmNvbSIsInNlY3JldCI6IjBmZTE1ZWYyNTMxZDUzM2I1MmI1OTk2OTU1NTk1ODllIn0.oJhlHxrBari6-Ewycy2jCg9Zz0x31ikX09E2PWn1oJY",
  // "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJtZnptODRMU25laG02MjdEUXRkbzBxRTM3bG8xcktRRWs5ZVc0bFpwWlFZIn0.eyJleHAiOjE2NzQ2NjAxNTksImlhdCI6MTY3NDYyNDE1OSwianRpIjoiMmYyY2QzMTctYjkyNS00YWJmLTkwNzctN2VjOGM1ZTU5YTRkIiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo5MDgwL2F1dGgvcmVhbG1zL1BhcmNlbDRZb3UiLCJhdWQiOlsiYWNjb3VudCIsInBhcmNlbDR5b3Utd2ViLWFwcCJdLCJzdWIiOiI5NjE2ZGNlMC05OTcwLTQyMWItODNjZS04MWYwOWNjYmMyZTQiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJteXBhcmNlbDR5b3Utd2ViLWFwcCIsInNlc3Npb25fc3RhdGUiOiJiYzdmNDAxOC05MmYyLTRlZTUtYTRkZC01Yjk2Zjg1YjkxZDgiLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbImh0dHA6Ly9sb2NhbGhvc3Q6MzAwMCJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiZGVmYXVsdC1yb2xlcy1wYXJjZWw0eW91Iiwib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7Im15cGFyY2VsNHlvdS13ZWItYXBwIjp7InJvbGVzIjpbInN1cGVyX2FkbWluIiwidXNlciJdfSwiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19LCJwYXJjZWw0eW91LXdlYi1hcHAiOnsicm9sZXMiOlsiZGVubWFyayIsInVzZXJfYWRtaW4iLCJzdXBlcl9hZG1pbiIsInVzZXIiXX19LCJzY29wZSI6ImVtYWlsIHByb2ZpbGUiLCJzaWQiOiJiYzdmNDAxOC05MmYyLTRlZTUtYTRkZC01Yjk2Zjg1YjkxZDgiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmFtZSI6IlJhenZhbiBGYXJ0ZSIsInByZWZlcnJlZF91c2VybmFtZSI6InJhenZhbkBwYXJjZWw0eW91LmNvbSIsImdpdmVuX25hbWUiOiJSYXp2YW4iLCJmYW1pbHlfbmFtZSI6IkZhcnRlIiwiZW1haWwiOiJyYXp2YW5AcGFyY2VsNHlvdS5jb20ifQ.OnlzDzbkdJV1cEvzeHoei777naeo7tAMDA63FGbYFpB8jVckpl7lBY_9y3MdkKx4Qjbv2_zVeSQRRQ2UKDA5hzp8KbQF1w8lCHck_TB50BmAaGO10XC6MTbnu1U9KyAQLDqbRGRlsBtS1wR5yO-AHJm5Z8DFUIJbzaG2MGA4l3kSqWA8-tqajiAPgTVbCMSbnGE4tv9wr0tUL_A80tzrkXCX9_ANVSCKWxr0hRhMYHDPM7hA3OY_4KHlJ2nWkh1IeTQFQoLFGbNk5X1e9frhTtHWnRqSJWLpWFak_utvhZOUXJzYBmYt_6JxgJK3G1hLbxrcK8pUHd6O6v1O-BdKjg"
  getPickupPoints(
    countryId?: string | number,
    supplierId?: string | number,
    zipCode?: string | number,
    address?: string,
    accountDetails?: any
  ): AxiosPromise<PickupPointsResponse> {
    const baseURL = axiosClient?.defaults?.baseURL?.replace(
      "/api/v1",
      "/customer_api/v1"
    );
    return axiosClient.get(
      `/pudo_points?country_id=${countryId}&supplier=${supplierId}&zip_code=${zipCode}&address_first_line=${address}`,
      {
        baseURL: baseURL,
        headers: {
          "Content-Type": "application/json",
          "Authorization-Token": accountDetails.customer_api_secret,
        },
      }
    );
  },
};

export default pickupPointService;
